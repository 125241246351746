<template>
  <div>
    <a-modal v-model="Show" title="申请人工介入" width="1200px" @ok="handleSubmit" @cancel="cancel">
      <a-spin :spinning="loading">
        <div style="width: 100%; height: 100%">
          <a-form-model ref="form" :model="form" :label-col="{ span: 3 }" :wrapper-col="{ span: 20 }">
            <a-form-model-item
              label="问题描述"
              :prop="'questionTitle'"
              :rules="{ required: true, message: '不能为空' }"
            >
              <a-input placeholder="请输入问题描述" v-model="form.questionTitle" />
            </a-form-model-item>
            <a-form-model-item label="电话号码" :prop="'telephone'" :rules="{ required: true, message: '不能为空' }">
              <a-input placeholder="请输入电话号码" v-model="form.telephone" />
            </a-form-model-item>
            <a-form-model-item
              label="详细描述"
              :prop="'questionComment'"
              :rules="{ required: true, message: '不能为空' }"
            >
              <div style="border: 1px solid #ccc">
                <Toolbar
                  style="border-bottom: 1px solid #ccc"
                  :editor="editor"
                  :defaultConfig="toolbarConfig"
                  :mode="mode"
                />
                <Editor
                  style="height: 400px; overflow-y: hidden"
                  v-model="form.questionComment"
                  :defaultConfig="editorConfig"
                  :mode="mode"
                  @onCreated="onCreated"
                />
              </div>
            </a-form-model-item>
            <a-form-model-item label="附件上传" class="form-item">
              <a-upload
                name="file"
                :multiple="true"
                :action="action"
                :headers="headers"
                @change="handleFileListChange"
                :beforeUpload="uploadBefore"
                :remove="handleFileListRemove"
                :fileList="fileList"
                class="avatar-uploader"
                accept=".doc, .xlsx, .pdf"
              >
                <a-button> <a-icon type="upload" />上传附件（.doc, .xlsx, .pdf） </a-button>
              </a-upload>
            </a-form-model-item>
          </a-form-model>
        </div>
      </a-spin>
    </a-modal>
  </div>
</template>
<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import VueCookeis from 'vue-cookies'
import { createQuestion } from '@/api/materialShop'

export default {
  name: 'Index',
  data () {
    const form = {
      // 问题描述
      questionTitle: null,
      // 联系电话
      telephone: null,
      // 详细描述
      questionComment: null,
      files: []
    }
    return {
      editor: null,
      html: '',
      toolbarConfig: {
        excludeKeys: [
          // 行内代码
          'code',
          // 图片
          'divider',
          'emotion',
          'insertLink',
          'editLink',
          'unLink',
          'viewLink',
          'codeBlock',
          'blockquote',
          'headerSelect',
          'header1',
          'header2',
          'header3',
          'header4',
          'header5',
          'todo',
          'redo',
          'undo',
          'fullScreen',
          'enter',
          'bulletedList',
          'numberedList',
          'insertTable',
          'deleteTable',
          'insertTableRow',
          'deleteTableRow',
          'insertTableCol',
          'deleteTableCol',
          'tableHeader',
          'tableFullWidth',
          'insertVideo',
          'uploadVideo',
          'editVideoSize'
        ]
      },
      editorConfig: { placeholder: '请输入内容...' },
      mode: 'default', // or 'simple'

      form: {
        ...form
      },
      Show: false,
      loading: false,
      details: {},
      action: `${process.env.VUE_APP_API_BASE_URL}/api/enterprise/employmentFile/EnterpriseFileController/uploadFile`,
      headers: {
        token: VueCookeis.get(ACCESS_TOKEN),
        clientId: process.env.VUE_APP_CLIENT_ID
      },
      fileList: []
    }
  },
  components: { Editor, Toolbar },
  created () {},
  methods: {
    uploadBefore (file) {
      const { size, name } = file
      const typeList = ['pdf', 'xlsx', 'doc']
      const dotIdx = name.lastIndexOf('.') // 获取最后一个.的位置
      const suffix = name.substr(dotIdx + 1) // 获取文件后缀
      if (this.fileList.length >= 10) {
        this.$message.warning('最多上传10个')
        return false
      }
      if (!typeList.includes(suffix.toLowerCase())) {
        this.$message.warning('文件格式不正确')
        return false
      } else if (size > 100 * 1024 * 1024) {
        this.$message.warning('文件大小不要超过100MB')
        return false
      } else {
        return true
      }
    },
    handleFileListChange (info) {
      if (info.file.status) {
        let fileList = [...info.fileList]
        fileList = fileList.map((file) => {
          if (file.response) {
            file.url = file.response.path
          }
          return file
        })
        this.fileList = fileList
        this.form.files = fileList.map((item) => ({
          path: item.url,
          name: item.name,
          uid: item.uid
        }))
      }
    },
    handleFileListRemove (file) {
      this.fileList = this.fileList.filter((item) => item.uid !== file.uid)
      this.form.files = this.form.files.filter(
        (item) => (item) => item.uid !== file.uid
      )
      return true
    },
    onCreated (editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
      console.log(editor.getConfig())
      // 上传地址
      editor.getConfig().MENU_CONF['uploadImage'] = {
        server: 'http://121.89.161.133:8081/api/enterprise/employmentFile/EnterpriseFileController/uploadFile',
        // form-data fieldName ，默认值 'wangeditor-uploaded-image'
        fieldName: 'file',

        // 单个文件的最大体积限制，默认为 2M
        maxFileSize: 1 * 1024 * 1024, // 1M

        // 最多可上传几个文件，默认为 100
        maxNumberOfFiles: 10,

        // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
        allowedFileTypes: ['image/*'],

        // 自定义上传参数，例如传递验证的 token 等。参数会被添加到 formData 中，一起上传到服务端。
        meta: {
          // token: VueCookeis.get(ACCESS_TOKEN)
          // otherKey: 'yyy'
        },

        // 将 meta 拼接到 url 参数中，默认 false
        metaWithUrl: false,

        // 自定义增加 http  header
        headers: {
          Accept: 'application/json, text/plain, */*',
          token: VueCookeis.get(ACCESS_TOKEN)
        },

        // 跨域是否传递 cookie ，默认为 false
        withCredentials: false,

        // 超时时间，默认为 10 秒
        timeout: 5 * 1000, // 5 秒
        customInsert (res, insertFn) {
          // JS 语法
          // res 即服务端的返回结果
          // 从 res 中找到 url alt href ，然后插图图片
          insertFn(res.path, res.fileName, res.path)
        },
        onBeforeUpload (file) {
          console.log(file)
          return file
        },
        onError: (file, err, res) => {
          this.$message.error('图片上传不能超过1M')
        }
      }
      console.log('工具栏配置', editor.getConfig())
      // console.log('工具栏配置', editor.getAllMenuKeys())
    },
    detail (val) {
      this.details = val
      this.Show = true
    },
    cancel () {
      this.details = {}
      this.Show = false
      this.form = {
        // 问题描述
        questionTitle: null,
        // 联系电话
        telephone: null,
        // 详细描述
        questionComment: null
      }
    },
    handleSubmit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const params = {
            id: this.details.id,
            questionTitle: this.form.questionTitle,
            telephone: this.form.telephone,
            questionComment: this.form.questionComment,
            files: JSON.stringify(this.form.files || [])
          }
          createQuestion({ ...params }).then((res) => {
            this.$message.success('提交成功，请等待人工处理')
            this.cancel()
            this.$parent.refresh()
          })
        }
      })
    }
  },
  beforeDestroy () {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  }
}
</script>
<style lang="less" scoped>
.div_size {
  font-size: 18px;
  margin-bottom: 20px;
}
</style>
<style src="@wangeditor/editor/dist/css/style.css"></style>
